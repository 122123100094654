const bottomElem = $('.more-news');
const elem = $('.main-page-banner-box');
const topY = elem[0].getBoundingClientRect().y;
const bottomY = bottomElem[0].getBoundingClientRect().bottom-topY-389;

$(window).scroll(() => {
    let scroll = $(window).scrollTop();
    const offset = elem.offset().top;
    const bottomBorder = bottomElem.offset().top
    // console.log(bottomBorder, scroll);
    if(scroll >= 900){
        elem.addClass('flyingBaner');
    } else {
        elem.removeClass('flyingBaner');
    }
    if(scroll + (window.innerHeight / 2) > bottomBorder+50) {
        elem[0].style.top = `${bottomY}px`
        elem.addClass('bottomBanner')
    } else {
        elem[0].style.top = `150px`
        elem.removeClass('bottomBanner')
    }
})